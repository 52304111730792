import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomBuildBasicRichTextEditorComponent } from './components/basic-custom-build/rich-text-editor.component';
// import { CkeditorRoutingModule } from './ckeditor-routing.module';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { FormsModule } from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    // CkeditorRoutingModule,
    CKEditorModule,
    FormsModule,
  ],
})
export class CkeditorModule {}
