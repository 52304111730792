<ul class="dropdown-menu dropdown-navbar-loggedin" aria-labelledby="navbarSettingsDropdown" *transloco="let t">
  <li class="dropdown-header">
    <p>{{t('navigation.settings')}}</p>
  </li>
  <!-- <li>
    <a class="dropdown-item" href="#">
      <i class="fa-solid fa-bell"></i>
      <div>
        <p>Notifications</p>
      </div>
    </a>
  </li> -->
  <li *ngIf="user">
    <a class="dropdown-item" [routerLink]="['/account']" routerLinkActive="active">
      <i class="fa-solid fa-user"></i>
      <div>
        <p>{{t('navigation.account')}}</p>
      </div>
    </a>
  </li>
  <li *ngIf="user">
    <a class="dropdown-item" [routerLink]="['/profile']" routerLinkActive="active">
      <i class="fa-solid fa-address-card"></i>
      <div>
        <p>{{t('common.profile')}}</p>
      </div>
    </a>
  </li>
  <li *ngIf="user">
    <a class="dropdown-item" [routerLink]="['/connections']" routerLinkActive="active">
      <i class="fa-solid fa-people-arrows"></i>
      <div>
        <p>{{t('navigation.connections')}}</p>
      </div>
    </a>
  </li>
  <!-- <li>
    <a class="dropdown-item" href="#">
      <i class="fa-solid fa-lock"></i>
      <div>
        <p>Permissions</p>
      </div>
    </a>
  </li> -->
  <!-- <li>
    <a class="dropdown-item" href="#">
      <i class="fa-solid fa-shield"></i>
      <div>
        <p>Security</p>
      </div>
    </a>
  </li> -->
  <li *ngIf="user?.roles && filterRoles(user.roles,['Admin','Developer'])">
    <a class="dropdown-item" [routerLink]="['/design']" routerLinkActive="active">
      <i class="fa-solid fa-paint-roller"></i>
      <div>
        <p>{{t('navigation.design')}}</p>
      </div>
    </a>
  </li>
  <li *ngIf="user?.roles && filterRoles(user.roles,['Interculturalist'])">
    <a class="dropdown-item" [routerLink]="['/hubs']" routerLinkActive="active">
      <i class="fa-brands fa-hubspot"></i>
      <div>
        <p>{{t('navigation.hub_management')}}</p>
      </div>
    </a>
  </li>
  <li>
    <a class="dropdown-item" (click)="doLogout()">
      <i class="fa-solid fa-right-from-bracket"></i>
      <div>
        <p>{{t('common.log_out')}}</p>
      </div>
    </a>
  </li>
</ul>