import { Component, computed, forwardRef, input, signal } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { RangeInputStep } from '../range-input-step.model';

@Component({
  selector: 'form-control-lickert-scale-bar',
  templateUrl: './form-control-lickert-scale-bar.component.html',
  styleUrls: ['./form-control-lickert-scale-bar.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormControlLickertScaleBarComponent),
      multi: true
    }
  ]
})
export class FormControlLickertScaleBarComponent implements ControlValueAccessor {

  steps = input.required<RangeInputStep[]>();
  min = input.required<number>();
  max = input.required<number>();
  stepSize = input.required<number>();
  volumeControlStyle = input<boolean>(); // Highlights all segments from zero up to the selected one
  minimumOpacity = input<number>(); // If you want opacity of segments to increase

  value = signal<number | undefined>(0);
  valueIsMin = computed(() => this.value() === this.min());
  valueIsMax = computed(() => this.value() === this.max());
  isDisabled: boolean = false;

  onChange: any = () => {};
  onTouched: any = () => {};

  writeValue(value: any): void {
    this.value.set(value);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  onLabelClick(value: any): void {
    this.value.set(parseInt(value));
    this.onChange(this.value());
    this.onTouched();
  }

  onInput(event: Event): void {
    const input = event.target as HTMLInputElement;
    this.value.set(parseInt(input.value));
    this.onChange(this.value());
    this.onTouched();
  }

  moveRight() {
    if(!this.valueIsMax() && this.value() + this.stepSize() <= this.max()) {
      this.value.set(this.value() + this.stepSize());
      this.onChange(this.value());
      this.onTouched();
    }
  }

  moveLeft() {
    if(!this.valueIsMin() && this.value() - this.stepSize() >= this.min()) {
      this.value.set(this.value() - this.stepSize());
      this.onChange(this.value());
      this.onTouched();
    }
  }
}