<div class="lickert-bar">
  <div class="active-step" id="active-step"><span
    *transloco="let t; scope: steps()[value()].labelTranslationScope"
    >
    {{t(steps()[value()].labelTranslationKey)}}
  </span></div>
  <div class="bar-container">
    <div class="chevron" id="chevron-left" (click)="moveLeft()">
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-left" viewBox="0 0 16 16">
        <path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0"/>
      </svg>
    </div>
    <div class="bar" id="lickert-slider">

      @for (step of steps(); track $index) {
        <div 
          class="segment" 
          [ngClass]="{'active': $index === value() || (volumeControlStyle() && $index <= value())}"
          (click)="onLabelClick(step.value)"
          [ngStyle]="{
            'opacity': minimumOpacity() === 0 || minimumOpacity() ? (100 * (step.value/max()))+'%' : '100%',
            'background-color' : (volumeControlStyle() && $index <= value() && step.colour) ? step.colour : ''
            }"
        >
      
        <span
                class="align-center"
                [ngClass]="{'text-muted': isDisabled}"
                [ngStyle]="{'cursor':isDisabled ? 'default':'pointer' }"
                *transloco="let t; scope: step.labelTranslationScope"
                >
                {{t(step.labelTranslationKey)}}
      </span>
      
      </div>
      }
    </div>
    <div class="chevron" id="chevron-right" (click)="moveRight()">
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
        <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"/>
      </svg>
    </div>
  </div>
</div>